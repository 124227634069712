var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "user-page"
    }
  }, [_c('div', {
    staticClass: "bg-image",
    style: "\n    background-image: url('https://images.unsplash.com/photo-1473448912268-2022ce9509d8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1925&q=80');\n  "
  }, [_c('div', {
    staticClass: "bg-primary-dark-op"
  }, [_c('div', {
    staticClass: "content content-full"
  }, [_c('div', {
    staticClass: "py-5 text-center"
  }, [_c('router-link', {
    staticClass: "img-link",
    attrs: {
      "to": "/profile"
    }
  }, [_c('img', {
    staticClass: "img-avatar img-avatar96 img-avatar-thumb",
    attrs: {
      "src": _vm.$auth.user.picture,
      "alt": ""
    }
  })]), _c('h1', {
    staticClass: "font-w700 my-2 text-white"
  }, [_vm._v(_vm._s(_vm.$auth.user.name))]), _c('h2', {
    staticClass: "h4 font-w700 text-white-75"
  }, [_vm._v(_vm._s(_vm.$auth.user.email))])], 1)])])]), _c('div', {
    staticClass: "content content-full content-boxed"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.errorAction.updateUser,
      expression: "errorAction.updateUser"
    }],
    staticClass: "alert alert-danger"
  }, [_vm._v(_vm._s(_vm.errorAction.updateUser))]), !_vm.$auth.user.email_verified ? _c('div', {
    staticClass: "alert alert-warning d-flex align-items-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "flex-fill ml-3"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('ACCOUNT_NOT_VERIFIED')) + " "), _c('a', {
    staticClass: "alert-link",
    attrs: {
      "href": "#"
    }
  }, [_vm._v(_vm._s(_vm.$t('CLICK_HERE')))]), _vm._v(" " + _vm._s(_vm.$t('EMAIL_VERIFICATION')) + " ")])])]) : _vm._e(), _c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content"
  }, [_c('form', {
    attrs: {
      "method": "POST",
      "enctype": "multipart/form-data"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit($event);
      }
    }
  }, [_c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-user-circle text-muted mr-1"
  }), _vm._v(" " + _vm._s(_vm.$t('USER_PROFILE')))]), _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.$t('USER_PROFILE_INFO')))])]), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "name",
      "type": "text",
      "name": "name",
      "label": _vm.$t('NAME')
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)]), _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-asterisk text-muted mr-1"
  }), _vm._v(" " + _vm._s(_vm.$t('CHANGE_PASSWORD')))]), _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.$t('CHANGE_PASSWORD_TEXT')))])]), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "password",
      "error": _vm.validationErrors.password,
      "type": "password",
      "label": _vm.$t('NEW_PASSWORD')
    },
    model: {
      value: _vm.form.password,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "password2",
      "error": _vm.validationErrors.password2,
      "type": "password",
      "label": _vm.$t('CONFIRM_NEW_PASSWORD')
    },
    model: {
      value: _vm.form.password2,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "password2", $$v);
      },
      expression: "form.password2"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-8 col-xl-5 offset-lg-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit"
    }
  }, [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" " + _vm._s(_vm.$t('UPDATE_PROFILE_BUTTON')))])])])])])])]), _vm.$auth.roles.includes('public_api_user') ? _c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content"
  }, [_c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-key text-muted mr-1"
  }), _vm._v(" " + _vm._s(_vm.$t('API_ACCESS')))]), _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.$t('API_ACCESS_TEXT')))]), _c('p', {
    staticClass: "text-danger font-w600"
  }, [_vm._v(_vm._s(_vm.$t('API_ACCESS_WARNING')))])]), _c('div', {
    staticClass: "col-lg-10 col-xl-8"
  }, [_vm._m(1), _vm.user.apiKey ? _c('div', {
    staticClass: "input-group mb-3"
  }, [_c('FormItem', {
    attrs: {
      "id": "apiKey",
      "value": _vm.apiKey,
      "disabled": true,
      "placeholder": "*************************************************************",
      "label": _vm.$t('API Key')
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('button', {
    staticClass: "btn btn-alt-info mr-3",
    attrs: {
      "disabled": _vm.loading.usage || _vm.loading.newKey || _vm.loading.getKey
    },
    on: {
      "click": _vm.onClickGetKey
    }
  }, [_vm.loading.getKey ? _c('span', [_c('b-spinner', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }), _vm._v(_vm._s(_vm.$t('LOADING')))], 1) : _c('span', [_vm._v(_vm._s(_vm.apiKey ? 'Hide' : 'View') + " API Key")])])])], 1) : _vm._e(), _vm.user.apiKey ? _c('button', {
    staticClass: "btn btn-primary mr-3",
    attrs: {
      "disabled": _vm.loading.usage || _vm.loading.newKey || _vm.loading.getKey
    },
    on: {
      "click": _vm.onClickViewUsage
    }
  }, [_vm.loading.usage ? _c('span', [_c('b-spinner', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }), _vm._v(_vm._s(_vm.$t('LOADING')))], 1) : _c('span', [_vm._v(_vm._s(_vm.$t('VIEW_USAGE_BUTTON')))])]) : _vm._e(), _c('button', {
    staticClass: "btn btn-alt-warning",
    attrs: {
      "disabled": _vm.loading.usage || _vm.loading.newKey || _vm.loading.getKey
    },
    on: {
      "click": function click($event) {
        _vm.modals.confirm = true;
      }
    }
  }, [_vm.loading.newKey ? _c('span', [_c('b-spinner', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }), _vm._v(_vm._s(_vm.$t('LOADING')))], 1) : _c('span', [_vm._v(_vm._s(_vm.$t('GENERATE_NEW_KEY_BUTTON')))])]), _vm.apiUsage.retrieved ? _c('div', {
    staticClass: "my-5"
  }, [_c('p', {
    staticClass: "font-w600 mb-1"
  }, [_vm._v("This month requests: " + _vm._s(_vm.apiUsage.currentMonth))]), _c('p', {
    staticClass: "font-w600 mb-3"
  }, [_vm._v("Last month requests: " + _vm._s(_vm.apiUsage.lastMonth))]), _c('p', {
    staticClass: "font-w600 mb-2"
  }, [_vm._v("Recent Requests")]), _c('table', {
    staticClass: "table table-striped table-borderless mb-5"
  }, [_c('tbody', _vm._l(_vm.apiUsage.recentRequests, function (request) {
    return _c('tr', {
      key: request._id
    }, [_c('th', [_vm._v(_vm._s(_vm._f("date")(request.createdAt, 'Do MMM YYYY HH:mm:ss')))]), _c('td', [_vm._v(_vm._s(request.message.replace('/2.0', '')))])]);
  }), 0)])]) : _vm._e()])])])]) : _vm._e()]), _c('ConfirmModal', {
    attrs: {
      "open": _vm.modals.confirm,
      "title": "Generate New API Key",
      "text": "You are about to generate a new API key. This will <strong>invalidate</strong> your existing API key and you'll need to ensure any applications using the old key are updated with the new key."
    },
    on: {
      "close": function close($event) {
        _vm.modals.confirm = false;
      },
      "submit": _vm.onClickSetupKey
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-00-auto"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-exclamation"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" For API documentation please check out: "), _c('a', {
    staticClass: "font-w600 text-primary",
    attrs: {
      "target": "_blank",
      "href": "https://docs.etainabl.com"
    }
  }, [_vm._v("https://docs.etainabl.com")])]);
}]

export { render, staticRenderFns }